.main {
    margin: 0;
    padding: 0;
    /* font-family: "Arial Narrow", Arial, sans-serif !important; */
   
    
}
*{
  font-family: 'Lato', sans-serif !important;
}
.hero {
   

    background-color: #999;
    background-image: url("./../assets/gallery/coverLG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

/* .title {
    color: #000;
    text-align: center;
    font-family: Suranna;
    font-size: 250px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px; /* 40% */
    /* letter-spacing: 57.5px;
    text-transform: uppercase;
    
}

.titleSub{
    text-align: center;
    font-family: Suranna;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px; 208.333% 
    letter-spacing: 11.04px;
    text-transform: uppercase; 
    padding: 1em;
} 



*/

.door-cover{
    /* background-image: url("./../assets/door1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center ;
    height: 30vh; */
    
}

.carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
  }
  
  .carousel .slide {
    background: #fff !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }

  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    /* bottom: 5% !important; */
  }
    
  /* Update the .myCarousel class to use Flexbox */
.myCarousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    margin-top: -6%;
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding: 6% 5% 8%;
    border: 1px solid #ddd;
    height: 560px; /* Change height to auto to accommodate varying content */
  }
  
  /* Adjust styles for consistency */
  .myCarousel h3 {
    color: #222;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
    margin: 0;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: none; /* Remove max-height */
    margin: 0;
  }
  
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      /* background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fafafa;
      margin-top: -6%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding: 6% 5% 8%;
      border: 1px solid #ddd;
      height: 650px; /* Change height to auto to accommodate varying content */
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }

  /* ... Your existing styles ... */

.intro {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #161616;
  transition: 1s;
}

.top-half .logo-header {
  bottom: 10%;
}

.bottom-half .logo-header {
  top: 10%;
}

.top-half {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.bottom-half {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.hidden {
  /* Remove display: none; */
}

.hidden .top-half {
  transform: translateY(-100vh);
  transition: 1s;
}

.hidden .bottom-half {
  transform: translateY(100vh);
  transition: 1s;
}

.logo-header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #D79F55;
}

.logo2 {
  position: relative;
  display: inline-block;
  bottom: -20px;
  opacity: 0;
}

.logo2.active {
  bottom: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}

.logo2.fade {
  bottom: 150px;
  opacity: 0;
  transition: ease-in-out 0.5s;
}
